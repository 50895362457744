import React from 'react';
import Swal from 'sweetalert2';

const AlertComponent = (props) => {
    Swal.fire({
        title: props.title,
        position: 'bottom-end',
        icon: 'success',
        html: props.text ? props.text : "",
        timer: props.timer ? props.timer : 3000,
        showConfirmButton: false,
        didOpen: () => {
        },
    })
}

export default AlertComponent;