import React from "react";

const item = (id) => {
    return (
        <div className="accordion-item p-3">
            <h2 className="accordion-header" id={"flush-headingOne" + id}>
                <button className="accordion-button collapsed h3-regular" type="button" data-bs-toggle="collapse"
                    data-bs-target={"#collapseOne" + id} aria-expanded="false" aria-controls={"collapseOne" + id}>
                    Accordion Item #{id}
                </button>
            </h2>
            <div id={"collapseOne" + id} className="accordion-collapse collapse" aria-labelledby={"headingOne" + id}
                data-bs-parent="#accordionfaqs">
                <div className="accordion-body h4-regular">
                    <strong>This is the first item's accordion body.</strong> It is hidden by default, until the collapse
                    plugin adds the appropriate classes that we use to style each element. These classes control the overall
                    appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with
                    custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go
                    within the <code>.accordion-body</code>, though the transition does limit overflow.
                </div>
            </div>
        </div>
    )
}

const FaqsView = () => {


    return (
        <div className="">
            <h1 className="h1-medium">Preguntas frecuentes</h1>
            <hr />
            <div className="accordion accordion-flush" id="accordionfaqs">
                {item(1)}
                {item(2)}
                {item(3)}
                {item(4)}
                {item(5)}
            </div>
        </div>
    )
}

export default FaqsView;