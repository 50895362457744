import React from 'react';

const ImageDrawer = (props) => {
    const img = props.meta.url;
    const text = props.meta.comment;

    return (

        <figure className='drawable-component'>
            <img alt={text} src={img} className="imagen-drawer w-100"/>
            <figcaption className="pie-imagen p1-regular">{text}</figcaption>
        </figure>
    )
}
export default ImageDrawer;