import { Link } from 'react-router-dom';
import { Text } from "@eo-locale/react";


function NotFound() {
    return (
        <div className="not-found">
            <h1>404</h1>
            <h2><Text id='tagNoEncontrado'/></h2>
            <p><Link to="/"><Text id='volverPaginaInicio'/></Link></p>
        </div>
    );
}

export default NotFound