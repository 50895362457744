import React, { useEffect, useState } from "react";
import { useTranslator } from "@eo-locale/react";


const ButtonLoader = (props) => {

    const { meta, onChange } = props;
    const [resource, setResource] = useState({ name: '', url: '' })
    const newTranslator = useTranslator();
    console.log('newtranslator')
    console.log(newTranslator)
    useEffect(() => {
        const res = {
            ...resource,
            ...meta
        }
        setResource(res);
    }, [])

    const updateResource = (res) => {
        setResource({
            ...resource,
            ...res
        })
    }

    const handleOnChangeName = (e) => {
        e.preventDefault()
        updateResource({ name: e.target.value })
    }

    const handleOnChangeUrl = (e) => {
        e.preventDefault()
        updateResource({ url: e.target.value })
    }

    const validate = (resource) => {
        const regexList = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi
        let res = regexList.test(resource.url)
        onChange(res, resource)
    }

    useEffect(() => {
        validate(resource)
    }, [resource])

    return (
        <div className="drawable-component">
            <textarea className="form-control mt-1" value={meta.name} onChange={handleOnChangeName} placeholder={newTranslator.messages.escribirTitulo} />
            <textarea className="form-control mt-1" value={meta.url} onChange={handleOnChangeUrl} placeholder={newTranslator.messages.pegarUrl} />
        </div>
    )
}
export default ButtonLoader;
