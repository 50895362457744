import React, { useEffect, useState } from 'react';
import Select, { components } from "react-select";
import { useTranslator } from "@eo-locale/react"

const DurationSelect = (props) => {
    const newTranslator = useTranslator();
    const [userPlans, setUserPlans] = useState([]);
    const [durations, setDurations] = useState([{
        hours: 6,
        plan: 'free',
    }]);
    const { Option } = components;
    const onDurationChange = props.onDurationChange;

    const isUserInPlan = (plan) => {
        return userPlans.includes(plan);
    }

    const durationToString = (hours) => {
        if (hours >= 48) {
            const newDays = hours / 24;
            return newDays + " " + newTranslator.messages.dias;
        } else {
            if (hours >= 24) {
                return "1 " + newTranslator.messages.dia;
            } else {
                if (hours > 1) {
                    return hours + " " + newTranslator.messages.horas;
                }
                else {
                    if (hours === 1) {
                        return "1 " + newTranslator.messages.hora;
                    }
                    else {
                        return newTranslator.messages.menosDeUnaHora;
                    }
                }
            }
        }
    }

    const [selectOptions, setSelectOptions] = useState(
        durations.map((duration) => {
            return {
                label: durationToString(duration.hours),
                value: duration.hours,
                isDisabled: !isUserInPlan(duration.plan),
                plan: duration.plan,
            }
        }));

    const [currentValue, setCurrentValue] = useState(selectOptions[0]);

    useEffect(() => {
        const newOptions = durations.map((duration) => {
            return {
                label: durationToString(duration.hours),
                value: duration.hours,
                isDisabled: !isUserInPlan(duration.plan),
                plan: duration.plan,
            }
        })
        const newCurrentValue = currentValue;
        const index = newOptions.findIndex((option) => {
            return option.value == newCurrentValue.value
        })
        if (index >= 0) {
            setCurrentValue(newOptions[index])
        }
        setSelectOptions(newOptions)
    }, [durations, newTranslator])

    useEffect(() => {
        setUserPlans(getUserPlans())
    }, [])

    const handleFetchError = (response) => {
        if (!response.ok) throw Error(response.status);
        return response.json();
    }

    useEffect(() => {
        const URL_API = process.env.REACT_APP_URL_API
        const url = `${URL_API}/api/durations`;
        fetch(url)
            .then(handleFetchError)
            .then((response) => {
                setDurations(response);
            })
            .catch((e) => {
                console.log("Error");
                console.log(e)
            });
    }, [])

    const onChange = (e) => {
        setCurrentValue(e)
        onDurationChange(e.value);
    }

    const getUserPlans = () => {
        //FIXME Agregar funcionalidad, verificar si el usuario tiene algun plan de pago y agregar L10N
        return ["free"]
    }

    const renderDisabledOption = (value, plan) => {
        return (<p style={{ maxHeight: ".5em" }}>{value} {getIconForPlan(plan)}</p>)
    }

    const renderEnabledOption = (value) => {
        return (<p style={{ maxHeight: ".5em" }}>{value}</p>)
    }

    const renderDuration = (hours, isDisabled, plan) => {
        return isDisabled ? renderDisabledOption(hours, plan) : renderEnabledOption(hours);
    }

    const getIconForPlan = (plan) => {
        //FIXME modificar la leyenda del title para el Icono l10n
        return (
            <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip" title={"Exclusivo del plan " + plan}>
                <img alt={plan} src="radio-tag.png" style={{ maxHeight: "1em", maxWidth: "1em" }} />
            </span>
        )
    }

    const DurationOption = properties => {
        return (
            <Option {...properties}>
                {renderDuration(properties.data.label, properties.data.isDisabled, properties.data.plan)}
            </Option>
        )

    }

    return <Select value={currentValue} defaultValue={selectOptions[0]} options={selectOptions} components={{ Option: DurationOption }} reset onChange={onChange} />

}
export default DurationSelect;