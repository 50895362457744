import React from 'react';
import { Footer, Searchbar } from '../components';
import micro from "../components/Micro3.svg";
import AboutUsView from "./AboutUsView";
import { Link, Element, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import FaqsView from "./FaqsView";
import { Text } from "@eo-locale/react";

function HomeView() {

    const scrollTo = () => {
        scroller.scrollTo('scroll-to-element', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }

    return (
        <div>
            <div className="home">
                <div className="search-home">
                    <section className="section-search">
                        <h1 className="h1-ligth mt-5"><Text id='escuchasteUnTag' /></h1>
                        <h1 className="h1-medium"><strong><Text id='buscalo' /></strong></h1>
                        <Searchbar c="big-search" />
                    </section>
                </div>

                <div className="micro" >
                    <img src={micro} alt="micro" className="micro-img img-fluid" />
                </div>
            </div>
            <Element id="aboutusView" name="aboutus" className="element aboutus">
                <AboutUsView />
            </Element>
            <Element id="faqsView" name="faqs" className="faqs">
                <FaqsView />
            </Element>
            <Element >
                <Footer />
            </Element>
        </div>
    );
}

export default HomeView