import React from 'react';
import whatsapp from "./icons-social/whatsapp-brands.svg"
import twitter from "./icons-social/twitter-brands.svg"
import instagram from "./icons-social/instagram-brands.svg"
import facebook from "./icons-social/facebook-f-brands.svg"
const Footer = () => {
    return (
        <div className="footer">
            <p className="h1-medium-white" style={{ textAlign: "center" }} >Tage.ar Footer</p>
            <div style={{ textAlign: "center" }}>
                <img src={whatsapp} alt="whatsapp" width="120" height="60" />
                <img src={twitter} alt="whatsapp" width="120" height="60" />
                <img src={instagram} alt="whatsapp" width="120" height="60" />
                <img src={facebook} alt="whatsapp" width="120" height="60" />

            </div>
        </div>
    )
}

export default Footer;
