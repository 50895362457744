import React from 'react';
import { css } from "@emotion/react";
import GridLoader from "react-spinners/GridLoader";

const override = css`
  display: block;
  margin: auto;
  margin-top: 10rem;
  border-color: #ff9900;
`;

const Loading = () => {
    let loading = true;
    let color = "#ff9900";
    return (
        <div className="sweet-loading">
            <GridLoader color={color} loading={loading} css={override} size={150} />
        </div>
    );
}

export default Loading;