import React, { useState, useEffect } from 'react';
import TextInput from "./TextInput";
import { Text, useTranslator } from "@eo-locale/react";
import SecondaryButton from './SecondaryButton';


const MAX_LENGTH_USER_NAME = 50;
const MAX_LENGTH_USER_DESCRIPTION = 500;

const UserEditor = (props) => {
    const newTranslator = useTranslator();
    const userObject = props.props;
    const onSave = props.onSave;
    const [resource, setResource] = useState({ user_name: '', description: '' })

    useEffect(() => {
        const res = {
            ...resource,
            ...userObject
        }
        setResource(res);
    }, [userObject])

    const handleOnChangeUserName = (e) => {
        let value = e.target.value;
        let res = {
            ...resource,
            display_name: value
        }
        updateResource(res);
    }

    const handleOnChangeDescription = (e) => {
        let value = e.target.value;
        let res = {
            ...resource,
            description: value
        }
        updateResource(res);
    }

    const updateResource = (resource) => {
        setResource(resource);
    }

    const handleOnSave = () => {
        onSave(resource);
    }

    const handleOnChangeImage = (e) => {
        let value = e.target.value;
        let res = {
            ...resource,
            profile_picture: value
        }
        updateResource(res)
    }

    return (
        <div className="drawable-component">
            <form className="form-group">
                <p className='font-tag mt-1 mb-1'><Text id='cambiarFotoPerfil' /></p>
                <TextInput
                    defaultValue={userObject.profile_picture}
                    onChange={handleOnChangeImage}
                    placeholder="Agregar un link a una imagen" />
                <p className='font-tag mt-1 mb-1'><Text id='nombreDeUsuario' /></p>
                <TextInput
                    maxLength={MAX_LENGTH_USER_NAME}
                    defaultValue={userObject.display_name}
                    placeholder="Agregar un nombre publico"
                    onChange={handleOnChangeUserName} />
                <p className='font-tag mt-1 mb-1'><Text id='descripcionUsuario' /></p>
                <textarea
                    maxLength={MAX_LENGTH_USER_DESCRIPTION}
                    defaultValue={userObject.description}
                    className="form-control mt-1"
                    placeholder="Agregar una descripcion"
                    onChange={handleOnChangeDescription} />
            </form>
            <div className="mt-2 d-flex flex-row-reverse">
                <SecondaryButton extraClass='p-1' dataBsDismiss='modal' text={newTranslator.messages.cancelar} />
                <SecondaryButton extraClass='p-1 me-1' dataBsDismiss='modal' onClick={handleOnSave} text={newTranslator.messages.guardar} />
            </div>
        </div>
    )
}

export default UserEditor;
