import React from 'react';
import { Link } from "react-router-dom";
import { InformationTagComponent } from '../components'
import { doNothing } from './DoNothing';
import SecondaryButton from './SecondaryButton';
import { useTranslator } from '@eo-locale/react';

const TagCard = (props) => {
    const newTranslator = useTranslator();
    const showEdit = props.showEdit;
    const item = props.item;

    return (
        <div className="card mt-4 card-radio-tag">
            <div className="card-body">
                <InformationTagComponent
                    nroviews={item.views}
                    nroreloj={item.expiration_date}
                />
                <Link to={`/${item.tag}`} className="text-decoration-none lista-resultados h3-regular">
                    <h4 className="chip-M p2-regular">#{item.tag}</h4>
                </Link>
                <Link to={`/${item.tag}`} className="text-decoration-none lista-resultados">
                    <h5 className="card-title mt-2">{item.title}</h5>
                </Link>
                <p className="card-text text-truncate">{item.description}</p>
                {
                    showEdit ?
                        <Link className="no-text-underline" to={`/edit/${item.tag}`} >
                            <div className="mt-1 d-flex flex-row-reverse">
                                <SecondaryButton text={newTranslator.messages.editarTag} />
                            </div>
                        </Link>
                        :
                        doNothing()
                }
            </div>
        </div>
    )
}
export default TagCard;