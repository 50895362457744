import React, { useState, useEffect } from 'react';
import TagCard from './TagCard';

const TagNameList = (props) => {
    const showEdit = props.showEdit;
    const user_id = props.id;
    const [nameListTags, setNameListTags] = useState([])

    useEffect(() => {
        const URL_API = process.env.REACT_APP_URL_API;
        const url = `${URL_API}/api/tags/user/${user_id}`;
        fetch(url)
            .then((res) => res.json())
            .then((res) => {
                setNameListTags(res);
            })
            .catch(() => {
            });
    }, [user_id]);

    return (
        <ul style={{ paddingLeft: "12px" }}>
            {nameListTags.map((item, index) => (
                <TagCard item={item} showEdit={showEdit} />
            ))}
        </ul>
    )
}
export default TagNameList