const orderMap = new Map();

class ResourceListService {

    cleanCache(tagId) {
        if (tagId) {
            orderMap.delete(tagId);
        } else {
            orderMap.delete("current");
        }
    }

    getPositions(tagId) {
        if (tagId) {
            return orderMap.get(tagId);
        } else {
            return orderMap.get("current");
        }
    }


    addIdToList(resourceId, tagId) {
        const newId = tagId ? tagId : "current";
        if (orderMap.has(newId)) {
            let array = orderMap.get(newId);
            if (!array.includes(resourceId)) {
                array.push(resourceId);
            }
        } else {
            let newArray = [resourceId];
            orderMap.set(newId, newArray);
        }
    }

    moveItemDown(resourceId, tagId) {
        const newId = tagId ? tagId : "current";
        if (orderMap.has(newId)) {
            let array = orderMap.get(newId);
            const index = array.indexOf(resourceId)
            if (index >= 0 && index < array.length - 1) {
                const positionsOfIndex = array[index]
                array.splice(index, 1);
                array.splice(index + 1, 0, positionsOfIndex)
            }
        }
    }

    deleteItem(id, tagId) {
        const newId = tagId ? tagId : "current";
        if (orderMap.has(newId)) {
            let array = orderMap.get(newId);
            const index = array.indexOf(id)
            if (index !== -1) {
                array.splice(index, 1)
            }
        }
    }
}
export default ResourceListService;
