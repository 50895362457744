const LocalizationStrings = [
    {
        language: 'es',
        messages: {
            cancelar: "Cancelar",
            confirmar: "Confirmar",
            ingresarTag: "Busca tu tag acá.",
            buscar: "Buscar",
            horas: "horas",
            duracion: "Duracion",
            descripcion: "Descripcion",
            titulo: "Titulo",
            disponible: "Disponible",
            formatoIncorrecto: "Formato incorrecto",
            noDisponible: "No disponible",
            tagYaNoEsValido: "Este Tag ya no es valido",
            leyendaDesactivadaCrear: "Aca va la leyenda",
            crear: "Publicar Tag",
            tipo: "Tipo",
            editarVistaPrevia: "Editar | Vista previa",
            borrarRecurso: "Borrar recurso",
            recursos: "Recursos",
            nuevoRecurso: "+ Añadir elemento",
            leyendaDesactivadaEditarVistaPrevia: "Aca va la leyenda del pre visualisar",
            mensajeDeAvisoPorExpiracion: "*Los tags marcados en rojo vencen antes que su tag.",
            relacionados: "Relacionados",
            youtubeVideo: "Video de youtube",
            image: "Imagen",
            paragraph: "Parrafo",
            dummy: "Dummy",
            markdown: "Markdown",
            button: "Boton",
            perfil: "Perfil",
            salir: "Salir",
            queEsRadioTag: "¿Que es radio tag?",
            quieroMiTag: "Quiero mi Tag",
            registrate: "Registrate",
            ingresa: "Ingresa",
            escuchasteUnTag: "¿Escuchaste un tag?",
            buscalo: "Buscalo",
            noSeEncontroCoincidencia: "No se encontro ninguna coincidencia",
            tagNoEncontrado: "Tag no encontrado",
            volverPaginaInicio: "Volver a la pagina de inicio",
            compartir: "Compartir",
            verPerfilUsuario: "Ver perfil del usuario",
            relacionadosConTuBusqueda: "Relacionados con tu busqueda",
            idDelUsuario: "Id del usuario: ",
            noConozcoEsteTipo: "Tipo no encontrado",
            ingresarTexto: "ingresar texto.",
            resultadosDeLaBusquedaDelTag: "Resultados de la busqueda del Tag #",
            escribirComentario: "ingresar comentario",
            pegarUrl: "pegar un link",
            escribirTitulo: "escribir titulo",
            noEncontrado: "No encontrado",
            tagNoExistente: "El Tag no existe",
            keycloakCaido: "Error!",
            copy: "Copiado!",
            inicioSesion: "Se inicio sesion correctamente!",
            upsAlgoSalioMal: "Ups.. algo salio mal!",
            titleUserView: "Editar perfil",
            tagTitleInput: "Por ejemplo: #Taggear",
            nombreDeTag: "Nombre del Tag",
            tituloInput: "Ponele un título a tu Tag",
            descripcionInput: "Brindá una breve descripción que acompañe a tu Tag",
            dias: "Dias",
            dia: "Dia",
            hora: "Hora",
            menosDeUnaHora: "Menos de una hora",
            vistas: "Vistas",
            vistaPrevia: "Vista previa",
            vence: "Vence en",
            cambiarFotoPerfil: "Cambiar foto de perfil",
            nombreDeUsuario: "Nombre de usuario",
            descripcionUsuario: "Descripcion de usuario",
            guardar: "Guardar",
            editarPerfil: "Editar perfil",
            tagsCreadosPor: "Tags creados por ",
            copiar: "Copiar",
            editarTag: "Editar Tag",
            cargando: "Cargando...",
        }
    },
    {
        language: 'en',
        messages: {
            cancelar: "Cancel",
            confirmar: "Confirm",
            ingresarTag: "Enter tag.",
            buscar: "Search",
            horas: "hours",
            duracion: "Duration",
            descripcion: "Description",
            titulo: "Title",
            disponible: "Available",
            formatoIncorrecto: "Incorrect format",
            noDisponible: "Not available",
            tagYaNoEsValido: "This Tag its not available",
            leyendaDesactivadaCrear: "Here goes the caption",
            crear: "Publish Tag",
            tipo: "Type",
            editarVistaPrevia: "Edit | Preview",
            borrarRecurso: "Delete resource",
            recursos: "Resources",
            nuevoRecurso: "+ Add item",
            leyendaDesactivadaEditarVistaPrevia: "Here goes the caption",
            mensajeDeAvisoPorExpiracion: "The tags in red expires before your own tag",
            relacionados: "Relates",
            youtubeVideo: "Youtube video",
            image: "Image",
            paragraph: "Paragraph",
            dummy: "Dummy",
            markdown: "Markdown",
            button: "Button",
            perfil: "Profile",
            salir: "Logout",
            queEsRadioTag: "What is radio tag?",
            quieroMiTag: "I want my Tag",
            registrate: "Register",
            ingresa: "Login",
            escuchasteUnTag: "Did you hear a Tag?",
            buscalo: "Search it",
            noSeEncontroCoincidencia: "Could not find a match",
            tagNoEncontrado: "Tag not found",
            volverPaginaInicio: "Return to homepage",
            compartir: "Share",
            verPerfilUsuario: "Go to user profile",
            relacionadosConTuBusqueda: "Related to your search",
            idDelUsuario: "User Id: ",
            noConozcoEsteTipo: "Type not found",
            ingresarTexto: "enter text.",
            resultadosDeLaBusquedaDelTag: "Results of Tag search #",
            escribirComentario: "enter comment",
            pegarUrl: "paste a link",
            escribirTitulo: "enter title",
            noEncontrado: "Not found",
            tagNoExistente: "the tag does not exist",
            keycloakCaido: "Error!",
            copy: "Copied!",
            inicioSesion: "Successfully logged in!",
            upsAlgoSalioMal: "Ups.. something went wrong!",
            titleUserView: "Edit profile",
            tagTitleInput: "For Example: #Taggear",
            nombreDeTag: "Tag's name",
            tituloInput: "Put a Title to your Tag",
            descripcionInput: "Provide a brief description to accompany your Tag",
            dias: "Days",
            dia: "Day",
            hora: "Hour",
            menosDeUnaHora: "Less than an hour",
            vistas: "Views",
            vistaPrevia: "Preview",
            vence: "Expires in",
            cambiarFotoPerfil: "Change profile picture",
            nombreDeUsuario: "User name",
            descripcionUsuario: "User description",
            guardar: "Save",
            editarPerfil: "Edit profile",
            tagsCreadosPor: "Tags created by ",
            copiar: "Copy",
            editarTag: "Edit Tag",
            cargando: "Loading...",

        }
    }
];

export default LocalizationStrings;