import React from 'react';

const ModalComponent = (props) => {
    /**
     * Manera de utilizarlo:
     *
     * - Debe implementar este boton para poder llamar al modal:
     *    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
     *       Launch demo modal
     *    </button>
     * - En la propiedad "data-bs-target" debe poner el nombre que quiera, este funcionara como id (debe hacerlo despues del #).
     * - Luego cuando implemente ese componente debe agregarle un Props que sea id y debe poner exactamente el mismo id que le puso al boton.
     * - Dentro de este componente debe insertar como hijo  lo que quiera mostrar.
     */


    const id = props.id ? props.id : "exampleModal";
    return(
        <div className="modal fade" id={id} tabIndex="-1" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{props.title}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ModalComponent;
