import React, { useEffect, useState } from 'react';
import Editor from 'rich-markdown-editor';

const MarkdownDrawer = (props) => {
    const data = props.meta.data;
    const [resource, setResource] = useState('')

    useEffect(() => {
        const res = {
            ...resource,
            ...data
        }
        setResource(res);
    }, [data])

    return (
        <div className="h4-regular">
            <Editor theme={{ background: "inherit" }} value={data} defaultValue={data} readOnly={true} />
        </div>
    )
}
export default MarkdownDrawer;