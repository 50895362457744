import React, { useEffect, useState } from 'react';
import { ResourceEditorList } from '.';
import { ResourceListService } from '../services/index'
import { useTranslator } from "@eo-locale/react";
import SecondaryButton from './SecondaryButton';

const resourcesMaxNumber = 30;
const defaultResource = {
    type: "",
    metadata: {},
    isValid: false,
}

const ResourceListHandler = (props) => {
    const newTranslator = useTranslator();
    const finishing = props.finishing
    const onFinish = props.onFinish
    const tagId = props.tagId;
    const newResourceService = new ResourceListService();
    const onResourceUpdate = props.createResources;
    const resourceIdArray = props.defaultResources;
    const [currentId, setCurrentId] = useState(0)
    const [idMap, setIdMap] = useState(new Map())
    const [orderedArray, setOrderedArray] = useState([])

    useEffect(() => {
        if (finishing === true) {
            newResourceService.cleanCache(tagId);
            onFinish();
        }
    }, [finishing])

    const updateResource = (id, res) => {
        setIdMap(idMap.set(id, res))
        updateParent1()
    }

    useEffect(() => {
        if (resourceIdArray) {
            getResources(resourceIdArray);
        }
    }, [])

    const addResource = (res) => {
        setIdMap(idMap.set(currentId, res));
        newResourceService.addIdToList(currentId, tagId);
        setCurrentId(currentId + 1)
        updateParent1()
    }

    const addResources = (resources) => {
        let newId = currentId;
        let newIdMap = new Map();
        resources.forEach((resource) => {
            newIdMap.set(newId, resource)
            newResourceService.addIdToList(newId, tagId)
            newId = newId + 1;
        })
        const combinedMap = new Map([...idMap, ...newIdMap]);
        setIdMap(combinedMap)
        setCurrentId(newId)
        updateParent(combinedMap)
    }

    const getResources = (list) => {
        const fetchResource = (resourceId) => {
            const URL_API = process.env.REACT_APP_URL_API;
            const url = `${URL_API}/api/resources/${resourceId}`;
            return fetch(url);
        }
        let promises = list.map(fetchResource);
        Promise.all(promises).then((values) => {
            let unmarshalPromises = values.map((response) => {
                return response.json();
            })
            Promise.all(unmarshalPromises).then((resourceObjects) => {
                const newResourceArray = resourceObjects.map((resourceObject) => {
                    const newResource = { ...resourceObject, isValid: true }
                    return newResource;
                });
                addResources(newResourceArray);
            }).catch(() => {
            });
        }).catch(() => {
        })
    }

    const deleteResource = (id, e) => {
        e.preventDefault();
        newResourceService.deleteItem(id, tagId)
        const newMap = idMap
        newMap.delete(id)
        setIdMap(newMap)
        updateParent1()
    }

    const moveResourceDown = (id) => {
        newResourceService.moveItemDown(id, tagId)
        updateParent1()
    }

    const getOrderedArrayMap = (array, map) => {
        const temp = map
            ? array.map((res) => {
                return map.get(res)
            })
            : array.map((res) => {
                return idMap.get(res)
            })
        setOrderedArray(temp);
        return temp;
    }

    const getOrderedArray = (array) => {
        const newIdMap = array.map((res) => {
            return idMap.get(res)
        })
        setOrderedArray(newIdMap)
        return newIdMap;
    }

    const updateParent = (idMap) => {
        let positions = newResourceService.getPositions(tagId);
        const orderedArray = getOrderedArrayMap(positions, idMap)
        onResourceUpdate(orderedArray)
    }

    const updateParent1 = () => {
        let positions = newResourceService.getPositions(tagId);
        const orderedArray = getOrderedArray(positions)
        onResourceUpdate(orderedArray)
    }

    const isResourcesLengthBelowMax = () => {
        return idMap.size < resourcesMaxNumber;
    }

    const addNewResource = (e) => {
        e.preventDefault();
        addResource(defaultResource)
    }

    return (
        <div>
            <ResourceEditorList orderedArray={orderedArray} idArray={newResourceService} onDelete={deleteResource} onUpdateResource={updateResource} moveResourceDown={moveResourceDown} tagId={tagId} />
            <div className="text-center">
                <SecondaryButton extraClass='btn-add-resources' onClick={addNewResource} disabled={!isResourcesLengthBelowMax()} text={newTranslator.messages.nuevoRecurso} />
            </div>
        </div>
    )
}
export default ResourceListHandler;
