import React from 'react';
import {doNothing} from "./DoNothing";

const TextInput = (props) => {
    let additionalClasses = props.classNames ? props.classNames : ''
    return (
        <>
            <input
                type="text"
                name={props.name}
                readOnly={props.readOnly}
                defaultValue={props.defaultValue}
                maxLength={props.maxLength}
                placeholder={props.placeholder}
                onChange={props.onChange}
                className={"form-control " + additionalClasses}
                aria-describedby={props.describedBy}
            />
            {props && props.msg ?
                <span className={props.msg.className}>{props.msg.content}</span>
                :doNothing()
            }
        </>
    )
}

export default TextInput;
