import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TagError } from '../views/errorViews';
import { TextInput } from "./index";
import { Text, useTranslator } from "@eo-locale/react";


export const searchTag = (query, onSuccess) => {
    const URL_API = process.env.REACT_APP_URL_API;
    let url = `${URL_API}/api/find/${query}`;
    fetch(url)
        .then(res => res.json())
        .then((result) => {
            onSuccess(result);
        })
        .catch((e) => {
            console.log(e)
            onSuccess(false)
        })
}

const Searchbar = (props) => {
    const [error, setError] = useState(false);
    const [query, setQuery] = useState('');
    const history = useHistory();
    const searchQuery = props.query;
    const style = props.c + " form-group d-flex justify-content-center";
    const newTranslator = useTranslator();
    console.log('newTranslator searchbar')
    console.log(newTranslator.messages)
    const validResult = (result) => {
        return Array.isArray(result) && (result.length > 0)
    }

    const hasOnlyResult = (result) => {
        return result.length === 1
    }

    const pushToOnlyResult = (result) => {
        history.push(`/${result[0].tag}`);
    }

    const hasPerfectMatch = (result) => {
        return result.some((item) => (item.tag === query))
    }

    const pushToPerfectMatch = () => {
        history.push(`/${query}`);
    }

    const defaultPush = (result) => {
        history.push({
            pathname: `/find/${query}`,
            state: { result: result }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // FIXME Agregar validacion.
        // Controlar de una lista de palabras claves que tengan que ver con la api
        // `find`, `tag`, `api` son palabras reservadas

        // if (query.trim()==="") {
        //     setError('No se encontro ninguna coincidencia'); // Usar codigos de error en number.
        // }

        searchTag(query, (result) => {
            if (result === false) {
                setError(true);
            } else {
                if (validResult(result)) {
                    if (hasOnlyResult(result)) {
                        pushToOnlyResult(result);
                    } else {
                        if (hasPerfectMatch(result)) {
                            pushToPerfectMatch();
                        } else {
                            defaultPush(result);
                        }
                    }
                } else {
                    defaultPush([]);
                }
            }
        });
    }

    const handleChange = (e) => {
        let valor = e.target.value;
        setQuery(valor);
    }

    return (
        error
            ?
            <TagError><Text id='upsAlgoSalioMal' /></TagError>
            :
            <form className={style} onSubmit={handleSubmit}>
                <TextInput
                    onChange={handleChange}
                    placeholder={newTranslator.messages.ingresarTag}
                    defaultValue={searchQuery}
                    classNames="d-inline me-2 input-search" />
                <button className="btn d-inline search" type="submit">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 19L13 13M15 8C15 8.91925 14.8189 9.82951 14.4672 10.6788C14.1154 11.5281 13.5998 12.2997 12.9497 12.9497C12.2997 13.5998 11.5281 14.1154 10.6788 14.4672C9.82951 14.8189 8.91925 15 8 15C7.08075 15 6.1705 14.8189 5.32122 14.4672C4.47194 14.1154 3.70026 13.5998 3.05025 12.9497C2.40024 12.2997 1.88463 11.5281 1.53284 10.6788C1.18106 9.82951 1 8.91925 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8Z" stroke="#212225" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </form>

    )
}

export default Searchbar;
