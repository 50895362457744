import React, { useState, useEffect, useContext } from 'react';
import { doNothing, TagEditor } from "../components";
import { useKeycloak } from "@react-keycloak/web";

const EditTagView = ({ match }) => {
    const tag = match.params.tag;
    const { keycloak } = useKeycloak()

    const [loaded, setLoaded] = useState(false);
    const [tagObject, setTagObject] = useState({});

    useEffect(() => {
        const URL_API = process.env.REACT_APP_URL_API;
        const url = `${URL_API}/api/${tag}`;
        console.log(url);
        fetch(url)
            .then((res) => res.json())
            .then((res) => {
                setTagObject(res);
                setLoaded(true);
            })
            .catch(() => {
                console.log("Error");
                setLoaded(false);
            });
    }, [tag]);

    const renderTagEditor = () => (
        <TagEditor tagObject={tagObject} keycloak={keycloak} bindings={false} />
    )

    return keycloak.authenticated && loaded ? renderTagEditor() : doNothing();
}

export default EditTagView;