import React from 'react';

const ParagraphDrawer = (props) =>{
    const texto = props.meta.text;
    return(
        <div className="drawable-component h4-regular">
            <p>
                {texto}
            </p>
        </div>
    )
}

export default ParagraphDrawer;