import React, { useState, useEffect } from 'react';
import { ResourceListHandler, TagForm, PrimaryButton } from "../components";
import { useHistory } from 'react-router-dom';
import { TagError } from '../views/errorViews';
import { TagPreView } from '../views'
import { Text, useTranslator } from "@eo-locale/react";

const TagEditor = (props) => {
    const tagObject = props.tagObject;
    const keycloak = props.keycloak;
    const tagAlreadyExists = tagObject && tagObject.tag ? true : false;
    const newTranslator = useTranslator();
    const [newTagObject, setNewTagObject] = useState({
        tag: {
            tag: tagObject && tagObject.tag ? tagObject.tag : '',
            title: tagObject && tagObject.title ? tagObject.title : '',
            description: tagObject && tagObject.description ? tagObject.description : '',
            //Aca pueden ir tambien hours
        },
        resources: [],
    });

    const [rlhFinishing, setRLHFinishing] = useState(false);
    const [redirectTag, setRedirectTag] = useState("");
    const [bindings, setBindings] = useState([]);
    const history = useHistory();
    const [error, setError] = useState(false);
    const [isValidResources, setIsValidResources] = useState(true);
    const [isValidTagForm, setIsValidTagForm] = useState(
        tagObject && tagObject.tag ? true : false);

    const createResources = (arrayResources) => {
        setNewTagObject(prevState => {
            const newResourceArray = {
                ...prevState,
                resources: arrayResources
            }
            return newResourceArray;
        });
    }

    const buildTag = () => {
        return {
            tag: newTagObject.tag.tag,
            title: newTagObject.tag.title,
            description: newTagObject.tag.description,
            duration: newTagObject.tag.hours,
        };
    }

    const buildTagWithBindings = () => {
        return {
            tag: newTagObject.tag.tag,
            title: newTagObject.tag.title,
            description: newTagObject.tag.description,
            duration: newTagObject.tag.hours,
            bindings: bindings
        };
    }

    const updateTagForm = (valid, tagForm) => {
        const updatedTag = {
            ...newTagObject,
            tag: {
                ...newTagObject.tag,
                ...tagForm
            }
        };
        setNewTagObject(updatedTag);
        setIsValidTagForm(valid);
    }

    const notifyChildrenToFinish = (res) => {
        setRedirectTag(res.tag)
        setRLHFinishing(true)
    }

    const handleResourceListHandlerOnFinish = () => {
        navigateToTag();
    }

    const navigateToTag = () => {
        history.push(`/${redirectTag}`);
    }

    const uploadTag = () => {
        const URL_API = process.env.REACT_APP_URL_API
        const url = `${URL_API}/api/tags`;
        const finalTag = buildTag();
        const method = tagAlreadyExists ? 'PATCH' : 'POST'
        fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Origin': 'https://tage.ar',
                'Authorization': "Bearer " + keycloak.token,
            },
            method: method,
            body: JSON.stringify(finalTag)
        })
            .then((res) => res.json())
            .then((res) => {
                if (newTagObject.resources.length <= 0) {
                    notifyChildrenToFinish(res)
                } else {
                    sendResources();
                }
            })
            .catch(() => {
                setError(true);
            })
    }

    useEffect(() => {
        if (bindings.length !== 0) {
            let tag;
            tag = buildTagWithBindings();
            addBindings(tag);
        }
    }, [bindings])

    const addBindings = (tag) => {
        const URL_API = process.env.REACT_APP_URL_API
        const url = `${URL_API}/api/tags`;
        fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Origin': 'https://tage.ar',
                'Authorization': "Bearer " + keycloak.token,
            },
            method: 'PATCH',
            body: JSON.stringify(tag)
        }).then((res) => res.json())
            .then((res) => {
                if (res.bindings.length === newTagObject.resources.length) {
                    notifyChildrenToFinish(res);
                }
            })
            .catch(() => {
                console.log("error")
            })
    }

    const sendResources = () => {
        const fetchResource = (resource) => {
            const URL_API = process.env.REACT_APP_URL_API
            const url = `${URL_API}/api/resources`;
            return fetch(url, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'true',
                    'Access-Control-Allow-Origin': 'https://tage.ar',
                    'Authorization': "Bearer " + keycloak.token,
                },
                method: 'POST',
                body: JSON.stringify(resource)
            })
        }

        let requests = newTagObject.resources.map(fetchResource);

        Promise.all(requests)
            .then((values => {
                let prom = values.map((response) => {
                    return response.json();
                })
                Promise.all(prom)
                    .then((resources) => {
                        let res = resources.map((resourcesObject) => {
                            return resourcesObject.id;
                        })
                        setBindings(res);
                    }).catch((error) => {
                        console.log(error)
                    });
            })
            ).catch((error) => {
                console.log(error)
            });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        //TODO 
        uploadTag();
    }

    const renderCreateButton = (value) => {

        const renderInnerContent = () => {
            return (
                < PrimaryButton
                    disabled={!validateTagAndResources()}
                    text={newTranslator.messages.crear}
                    onClick={value}
                    extraClass={'btn-block tag-button-create mt-4'}
                />
            )
        }

        const renderWithTooltip = (title) => (
            <span style={{ outline: "none" }} tabIndex="0" data-bs-toggle="tooltip"
                title={title}>
                {renderInnerContent()}
            </span>
        )

        const renderWithoutTooltip = renderInnerContent;

        return (
            <div className="mt-lg-2 mb-lg-4">
                {!validateTagAndResources ?
                    renderWithTooltip(newTranslator.messages.leyendaDesactivadaCrear)
                    :
                    renderWithoutTooltip()
                }
            </div>
        )
    }

    const validateTagAndResources = () => {
        return isValidResources && isValidTagForm;
    }

    const Separator = () => (
        <hr style={{ border: '1px solid var(--divider-color)', width: '100%' }} />
    )

    const renderTagForm = () => {
        return <div>
            {(tagObject != null && tagObject.tag != null && tagObject.tag.trim() !== "")
                ?
                <TagForm
                    defaultTag={tagObject}
                    editTagName={false}
                    onChange={updateTagForm}
                />
                :
                <TagForm
                    editTagName={true}
                    onChange={updateTagForm}
                />
            }
            <Separator />
        </div>
    }

    const renderResources = () => {
        if (tagObject != null && Array.isArray(tagObject.bindings) && (tagObject.bindings.length > 0)) {
            return <ResourceListHandler
                defaultResources={tagObject.bindings}
                createResources={createResources} tagId={tagObject.tag}
                onFinish={handleResourceListHandlerOnFinish}
                finishing={rlhFinishing} />
        } else {
            return <ResourceListHandler
                createResources={createResources}
                onFinish={handleResourceListHandlerOnFinish}
                finishing={rlhFinishing} />
        }
    }

    return (
        error ?
            <TagError><Text id='keycloakCaido' /></TagError>
            :
            (<div>
                <div className="edit-tag-view-card">
                    <div action="" className="container d-flex flex-column edit-tag" >
                        {renderTagForm()}
                        {renderResources()}
                    </div>
                    <TagPreView className="preview-tag" tagObject={{ newTagObject }} />
                </div>
                {renderCreateButton(handleSubmit)}
            </div>
            )
    )
}
export default TagEditor;