import React, { useState, useEffect } from 'react';
import TagCard from './TagCard';


const RelatedTags = ({ match }) => {
    const query = match;
    const [results, setResults] = useState([]);

    useEffect(() => {
        const URL_API = process.env.REACT_APP_URL_API;
        let url = `${URL_API}/api/tags/relates/${query}`;
        fetch(url)
            .then(res => res.json())
            .then((result) => {
                setResults(result)
            })
            .catch(() => {
                console.log("error")
            })
    }, [query])

    return (
        <div style={{ margin: 0 }}>
            {results.map((item, index) => (
                <div key={index}>
                    <TagCard item={item} />
                </div>
            ))
            }
        </div>
    )
}

export default RelatedTags;