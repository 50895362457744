import React from "react";
import logoError from './Frame.svg';
const TagError = (props) => {
    const message = props.children ? props.children : "props.location.state.message";
    return (
        <div className="container mt-5 d-flex justify-content-center">
            <div className="card card-error" style={{ border: 'none' }}>
                <img src={logoError} className="card-img-top" style={{ width: '100%' }} alt="image error" />
                <h3 className="card-title text-center">{message}</h3>
            </div>
        </div>
    )
}
export default TagError;