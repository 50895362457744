import React, { useEffect, useState } from "react";
import ResourceEditor from "./ResourceEditor";

const ResourceEditorList = (props) => {
    const [orderedArray, setOrderedArray] = useState(props.orderedArray)
    const tagId = props.tagId;
    const idArray = props.idArray;
    const updateResource = props.onUpdateResource;
    const moveResourceDown = props.moveResourceDown;

    useEffect(() => {
        setOrderedArray(props.orderedArray)
    }, [props.orderedArray])

    const onResourceChange = (resource, id) => {
        updateResource(id, resource)
    }

    const onElementDown = (e, id) => {
        e.preventDefault()
        moveResourceDown(id)
    }

    const renderResourceList = () => {
        const idArr = idArray.getPositions(tagId);
        return orderedArray.map((value, index, arr) => {
            const id = idArr[index]
            return <ResourceEditor
                resource={value}
                key={id}
                id={id}
                onDelete={props.onDelete}
                onChange={onResourceChange}
                onElementDown={onElementDown}
                renderDownButton={index < arr.length - 1} />
        })
    }

    return (
        <div>
            {renderResourceList()}
        </div>
    )
}
export default ResourceEditorList;
