import React from "react";

const SecondaryButton = (props) => {
    const svg = props.svg ? props.svg : undefined;
    const disabled = props.disabled ? props.disabled : false;
    const text = props.text;
    const onClick = props.onClick;
    const extraClass = props.extraClass;
    const dataBsToggle = props.dataBsToggle ? props.dataBsToggle : undefined;
    const dataBsTarget = props.dataBsTarget ? props.dataBsTarget : undefined;
    const dataBsDismiss = props.dataBsDismiss ? props.dataBsDismiss : undefined;
    const href = props.href ? props.href : undefined;
    const target = props.target ? props.target : undefined;

    return (
        <a
            className={'btn tag-button button-drawer ' + extraClass}
            disabled={disabled}
            onClick={(e) => {
                if (onClick)
                    onClick(e)
            }}
            data-bs-toggle={dataBsToggle}
            data-bs-target={dataBsTarget}
            data-bs-dismiss={dataBsDismiss}
            href={href}
            target={target}
        >
            {svg}
            {text}</a>
    )
}
export default SecondaryButton;