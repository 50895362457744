import React, { useState, useEffect } from 'react';
import { TextInput } from "./index";
import { useTranslator } from "@eo-locale/react";
import { useDebouncedCallback } from 'use-debounce';


const MAX_LENGTH = 20;

const TagNameEditor = (props) => {
    const {
        defaultTag,
        editTagName,
        onChange,
    } = props;
    const [value, setValue] = useState(defaultTag)
    const [message, setMessage] = useState({
        msgError: '',
        styleTag: '',
        styleSpan: '',
    });
    const newTranslator = useTranslator();

    const debounced = useDebouncedCallback(
        (value) => {
            setValue(value);
        },
        800
    );

    useEffect(() => {
        handleChangeTag(value)
    }, [value])

    const noError = () => {
        setMessage({
            msgError: '',
            styleTag: '',
            styleSpan: '',
        })
    }
    const incorrectTagFormat = () => {
        setMessage({
            msgError: newTranslator.messages.formatoIncorrecto,
            styleSpan: 'invalid-feedback',
            styleTag: 'is-invalid',
        })
    }
    const availableTag = () => {
        setMessage({
            msgError: newTranslator.messages.disponible,
            styleSpan: 'valid-feedback',
            styleTag: 'is-valid',
        })
    }
    const unavailableTag = () => {
        setMessage({
            msgError: newTranslator.messages.noDisponible,
            styleSpan: 'invalid-feedback',
            styleTag: 'is-invalid',
        })
    }

    const validateTagFormat = (tag) => {
        let aux = "^[A-z0-9ñ]{0," + MAX_LENGTH + "}$"
        let pattern = new RegExp(aux, 'g');
        return tag.match(pattern);
    }

    const formatTagName = (input) => {
        let output = input;
        const limitInput = () => {
            output = output.substring(0, MAX_LENGTH)
        }
        //Reemplazar los caracteres con acentos a su valor normal
        const replaceAcuteAccents = () => {
            output = output
                .replace(/á/g, "a").replace(/Á/g, "A")
                .replace(/é/g, "e").replace(/É/g, "E")
                .replace(/í/g, "i").replace(/Í/g, "I")
                .replace(/ó/g, "o").replace(/Ó/g, "O")
                .replace(/ú/g, "u").replace(/Ú/g, "U")
                .replace(/ü/g, "u").replace(/Ü/g, "U");

        }
        //Agarrar los caracteres especiales y convertirlos a espacios, menos enie y con acento
        const replaceSpecialChars = () => {
            output = output.replace(/[^a-zA-ZñÑ\d]/g, " ")
        }

        //Agarrar el caracter de la derecha de un espacio se convierte en mayuscula
        //Borrar todos los espacios
        const convertToUpperCaseAndDeleteWhiteSpaces = () => {
            output = output.split(/\s/)
                .map((word, index) => {
                    if (index === 0) return word;
                    return word.charAt(0)
                        .toUpperCase() + word.slice(1)
                })
                .join("");
        }

        replaceAcuteAccents();
        replaceSpecialChars();
        convertToUpperCaseAndDeleteWhiteSpaces();
        limitInput();
        return output;
    }

    function validateAvailability(tag) {
        const URL_API = process.env.REACT_APP_URL_API
        const url = `${URL_API}/api/tags/available/${tag}`;
        return fetch(url)
            .then(res => res.json())
            .then(j => j === true)
            .catch((e) => false);
    }

    const loading = () => {
        setMessage({
            msgError: newTranslator.messages.cargando,
            styleSpan: 'valid-loading',
            styleTag: '',
        })
    }

    const handleChangeTag = (res) => {
        if (editTagName) {
            const trimmedTag = res.trim();
            if (trimmedTag === '') {
                noError();
                onChange(false, trimmedTag);
                return;
            }
            const formattedTag = formatTagName(trimmedTag)
            if (!validateTagFormat(formattedTag)) {
                incorrectTagFormat();
                onChange(false, formattedTag)
                return;
            }
            const isAvailablePromise = validateAvailability(formattedTag);
            isAvailablePromise.then((isAvailable) => {
                if (isAvailable) {
                    availableTag();
                } else {
                    unavailableTag();
                }
                onChange(isAvailable, formattedTag);
            })
        }
    }

    const withDefault = value != null;
    const isTagNameEditable = editTagName != undefined ? editTagName : true;
    const defaultTagName = withDefault ? value : "";

    return (
        <div>
            <TextInput
                readOnly={!isTagNameEditable}
                defaultValue={defaultTagName}
                maxLength={MAX_LENGTH * 2}
                placeholder={newTranslator.messages.tagTitleInput}
                onChange={(e) => {
                    loading();
                    debounced(e.target.value);
                }}
                classNames={message.styleTag}
                msg={{ className: message.styleSpan, content: message.msgError }} />
        </div>
    )
}
export default TagNameEditor;