import React, { useState, useEffect } from "react";
import { Drawer } from ".";

const Resource = (props) => {
    const id = props.id;
    const [resource, setResource] = useState({});

    useEffect(() => {
        const URL_API = process.env.REACT_APP_URL_API;
        const url = `${URL_API}/api/resources/${id}`;
        console.log(url);
        fetch(url)
            .then((res) => res.json())
            .then((res) => {
                setResource(res);
            })
            .catch(() => {
                console.log("Error");
            });
    }, [id]);

    return (
        <div className="mt-3 mb-4">
            <Drawer resource={resource} />
        </div>
    );
};
export default Resource;
