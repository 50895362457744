import React, { useEffect, useState } from 'react';
import { Searchbar, searchTag } from '../components';
import TagCard from '../components/TagCard';
import { Text } from "@eo-locale/react";


const ListView = (props) => {
    const query = props.match.params.query;
    const [results, setResults] = useState([]);

    useEffect(() => {
        if (props.location.state != null) {
            setResults(props.location.state.result);
        } else {
            searchTag(query, (result) => {
                setResults(result);
            });
        }
    }, [query])

    const renderList = () => {

        //FIXME Definir comportamiento recibir un Result vacio.
        const renderEmptyResult = () => {
            return (<Text id='noSeEncontroCoincidencia' />);
        }

        const renderResults = () => {
            return results.map((item, index) => (
                <li style={{ listStyle: "none" }} key={index}>
                    <TagCard item={item} />
                </li>
            ))
        }

        return (
            <div className="container">
                <Searchbar c="small-search mt-4" query={query} />
                <h3 className="h3-regular mt-2" ><Text id='resultadosDeLaBusquedaDelTag' />{query}</h3>
                <ul className="lista-resultados">
                    {results.length !== 0 ? renderResults() : renderEmptyResult()}
                </ul>
            </div>
        )
    }

    return renderList();
}

export default ListView