import React from 'react';
import reloj from "./reloj.svg";
import view from "./view.svg"
import moment from 'moment';
import { Text } from "@eo-locale/react";


const InformationTagComponent = (props) => {
    const { nroviews, nroreloj } = props;
    const timedif = () => {
        let date2 = moment(nroreloj);
        return date2.diff(moment(), "hours");
    }
    return (
        <div className="container-fluid d-flex">
            <div className="d-flex me-auto">
                <img src={view} alt="views" width="22" height="22" />
                <p className="p1-regular">{nroviews} <Text id='vistas' /></p>
            </div>
            <div className="d-flex ms-auto">
                <img src={reloj} alt="views" width="22" height="22" />
                <p className="p1-regular"><Text id='vence' /> {timedif()} <Text id='horas' /></p>
            </div>
        </div>
    )
}

export default InformationTagComponent;