import React from "react";

const PrimaryButton = (props) => {
    const disabled = props.disabled;
    const text = props.text;
    const onClick = props.onClick;
    const extraClass = props.extraClass;

    return (
        <button
            className={'btn primary-button ' + extraClass}
            disabled={disabled}
            onClick={(e) => onClick(e)}
        >{text}</button>
    )
}
export default PrimaryButton;