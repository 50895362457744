import React, { useState } from 'react';
import {
    FacebookShareButton,
    TwitterShareButton,
    RedditShareButton,
    EmailShareButton,
    TelegramShareButton,
    WhatsappShareButton, WhatsappIcon

} from "react-share";
import {
    FacebookIcon,
    TwitterIcon,
    RedditIcon,
    EmailIcon,
    TelegramIcon,

} from "react-share";
import { TextInput } from "./index";
import { useTranslator } from "@eo-locale/react";

const ShareMenu = (props) => {
    const url = process.env.REACT_APP_URL + "/" + props.tag;
    const iconProps = { size: 60, round: true };
    const newTranslator = useTranslator();
    const [copied, setCopiado] = useState(newTranslator.messages.copiar);

    const copy = () => {
        document.querySelector("input[name='inputLink']").select();
        document.execCommand('copy');
        setCopiado(newTranslator.messages.copy);
    }

    return (
        <div className="d-block text-center">
            <div className="">
                <FacebookShareButton url={url}>
                    <FacebookIcon {...iconProps} />
                </FacebookShareButton>
                <TwitterShareButton url={url} >
                    <TwitterIcon {...iconProps} />
                </TwitterShareButton>
                <RedditShareButton url={url} >
                    <RedditIcon {...iconProps} />
                </RedditShareButton>
                <EmailShareButton url={url}>
                    <EmailIcon {...iconProps} />
                </EmailShareButton>
                <TelegramShareButton>
                    <TelegramIcon {...iconProps} />
                </TelegramShareButton>
                <WhatsappShareButton>
                    <WhatsappIcon {...iconProps} />
                </WhatsappShareButton>
            </div>
            <div className="input-group mb-1 mt-2">
                <button className="btn btn-outline-secondary" type="button" id="button-addon1" onClick={() => copy()}>{copied}</button>
                <TextInput
                    name="inputLink"
                    defaultValue={url}
                    describedBy="button-addon1" />
            </div>
        </div>
    )
}

export default ShareMenu;
