import React, { useState, useEffect, Fragment } from 'react';
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import iconRadioTag from "./iconRadioTag.svg";
import { HashLink } from 'react-router-hash-link';
import { TranslationsContext, useTranslator, Text } from "@eo-locale/react";

const Nav = () => {
    const { keycloak, initialized } = useKeycloak()
    const [login, setLogin] = useState({});
    const language = useTranslator();
    console.log(language)

    useEffect(() => {
        if ((keycloak != null) && initialized) {
            keycloak.loadUserProfile()
                .then(userInfo => {
                    setLogin(userInfo);
                })
        }
    }, [keycloak, initialized])

    const logoutUser = () => {
        setLogin({});
        keycloak.logout({ redirectUri: window.location.href });
    }

    const selectLanguage = () => {
        return (
            <TranslationsContext.Consumer>
                {({ setLanguage }) => (
                    <select className='select' value={language.language}
                        onChange={(e) => {
                            localStorage.setItem('language', e.target.value)
                            return setLanguage(e.target.value);
                        }}>
                        <option value="es">es</option>
                        <option value="en">en</option>
                    </select>
                )}
            </TranslationsContext.Consumer>
        )
    }

    const dropdown = () => {
        return (
            <div className="dropdown">
                <button className="btn  dropdown-toggle" type="button"
                    id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.8284 7.82843C11.5786 7.07828 12 6.06087 12 5C12 3.93913 11.5786 2.92172 10.8284 2.17157C10.0783 1.42143 9.06087 1 8 1C6.93913 1 5.92172 1.42143 5.17157 2.17157C4.42143 2.92172 4 3.93913 4 5C4 6.06087 4.42143 7.07828 5.17157 7.82843C5.92172 8.57857 6.93913 9 8 9C9.06087 9 10.0783 8.57857 10.8284 7.82843Z" stroke="#212225" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M3.05025 14.0503C4.36301 12.7375 6.14348 12 8 12C9.85652 12 11.637 12.7375 12.9497 14.0503C14.2625 15.363 15 17.1435 15 19H1C1 17.1435 1.7375 15.363 3.05025 14.0503Z" stroke="#212225" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {login.username}
                </button>

                <ul className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton2">
                    <li><Link className="dropdown-item" to={`/users/${login.username}`}><Text id='perfil' /></Link></li>
                    <li className="dropdown-item" onClick={() => logoutUser()}><Text id='salir' /></li>
                </ul>
            </div>
        )
    }

    const listElementsNotAuthenticated = () => {
        return (
            <Fragment>
                <li className="nav-item">
                    <button className="button-nav">
                        <HashLink className="nav-link tag-button h4-regular" to='/#aboutusView' ><Text id='queEsRadioTag' /></HashLink>
                    </button>
                </li>
                <li className="nav-item">
                    <button className="button-nav">
                        <HashLink className="nav-link tag-button h4-regular" to='/#faqsView' >FAQS</HashLink>
                    </button>
                </li>
            </Fragment>
        )
    }
    const listElementsAuthenticated = () => {
        if (keycloak.authenticated) {
            return (
                <Fragment>
                    <li className="nav-item">
                        <button className="button-nav">
                            <Link className="nav-link tag-button h4-regular" to="/create"><Text id='quieroMiTag' /></Link>
                        </button>
                    </li>
                    <li className=" nav-item">
                        {dropdown()}
                    </li>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <li className="nav-item">
                        <button className="button-nav">
                            <a className={!initialized ? "nav-link tag-button h4-regular isDisabled" : "nav-link h4-regular tag-button"} type="button" onClick={() => keycloak.login()} ><Text id='quieroMiTag' /></a>
                        </button>
                    </li>
                    <li className={!initialized ? "nav-item isDisabled" : "nav-item"} onClick={() => keycloak.register()}>
                        <button className="button-nav">
                            <Link className=" nav-link tag-button h4-regular" to=""><Text id='registrate' /></Link>
                        </button>
                    </li>
                    <li className={!initialized ? "nav-item isDisabled h4-regular" : "nav-item"} onClick={() => keycloak.login({ redirectUri: window.location.href })} >
                        <button className="button-nav">
                            <Link className=" nav-link tag-button h4-regular" to=""><Text id='ingresa' /></Link>
                        </button>
                    </li>
                </Fragment>
            )
        }
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid ">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <svg width="30" height="30" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 13H17M1 1H17H1ZM1 7H9H1Z" stroke="#212225" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
                <Link className="navbar-brand" to="/">
                    <img src={iconRadioTag} alt="" width="120" height="60"
                        className="d-inline-block align-top me-1 ms-1" />
                </Link>
                <div className="collapse navbar-collapse d-lg-flex justify-content-end" id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        {listElementsNotAuthenticated()}
                        {listElementsAuthenticated()}
                    </ul>
                    {selectLanguage()}
                </div>
            </div>
        </nav>

    )
}

export default Nav;