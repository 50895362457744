import React from "react";

const AboutUsView = () => {
    return (
        <div className="aboutus-grid">
            <div className="column1">
                <section className="">
                    <h2 className="h1-medium-white">Titulo 1</h2>
                    <p className="h3-regular-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam est, habitant libero, et. Vulputate montes, dignissim ipsum molestie ac iaculis praesent. Non egestas proin elit, enim neque, fermentum tristique.</p>
                </section>
            </div>
            <div className="column2">
                <section className="">
                    <h2 className="h1-medium-white">Titulo 2</h2>
                    <p className="h3-regular-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam est, habitant libero, et. Vulputate montes, dignissim ipsum molestie ac iaculis praesent. Non egestas proin elit, enim neque, fermentum tristique.</p>
                </section>
            </div>
        </div>
    )
}

export default AboutUsView;