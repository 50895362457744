import React, { useState } from 'react';
import { getVideoInfo } from "youtube-video-exists";
import { useTranslator } from "@eo-locale/react";

const MAX_TITLE_LENGTH = 50;
const MAX_COMMENT_LENGTH = 300;
const YoutubeLoader = (props) => {
    const newTranslator = useTranslator();
    const { meta, onChange } = props;
    const [resource, setResource] = useState({
        title: meta && meta.title ? meta.title : '',
        comment: meta && meta.comment ? meta.comment : '',
        url: meta && meta.url ? meta.url : ''
    })

    const extractKey = (url) => {
        let result = null;
        const validateRegex = (regex) => {
            let matchList = (regex.exec(url));
            if (matchList != null && Array.isArray(matchList) && matchList.length >= 2) {
                result = matchList[1];
            }
        }

        const regexList = [
            /.*?www.youtube.com\/watch\?v=([^&\s]{5,15})(?:&.*|$)/gi,
            /.*?youtu.be\/([^&\s?]{5,15}).*/gi,
            /^((?:[^&\s=:\/?]){5,15})/gi
        ];

        if (url != null && url !== "") {
            for (const index in regexList) {
                validateRegex(regexList[index]);
                if (result != null) {
                    return result;
                }
            }
        }
        return null;
    }

    const validateYoutube = async (url) => {
        return (await getVideoInfo(url)).existing;
    }

    const handleOnChangeTitle = (e) => {
        let value = e.target.value;
        let res = {
            ...resource,
            title: value
        }
        updateResource(res);
    }

    const validator = async (resource) => {
        let valid = validateComment(resource.comment) && validateTitle(resource.title) && (await validateYoutube(resource.url));
        setResource(resource)
        onChange(valid, resource)
    }

    const validateComment = (comment) => {
        return comment.length <= 300;
    }

    const validateTitle = (title) => {
        return title.length <= 50;
    }

    const handleOnChangeComment = (e) => {
        let value = e.target.value;
        let res = {
            ...resource,
            comment: value
        }
        updateResource(res);
    }

    const handleOnChangeUrl = (e) => {
        let value = e.target.value;
        let link = value
        let url = extractKey(link);
        let res = {
            ...resource,
            link: link,
            url: url
        }
        updateResource(res)
    }

    const updateResource = (resource) => {
        validator(resource);
    }

    return (

        <div className="drawable-component">
            <textarea maxLength={MAX_TITLE_LENGTH} className="form-control mt-1" value={meta.title} onChange={handleOnChangeTitle} placeholder={newTranslator.messages.escribirTitulo} type="text" />
            <textarea maxLength={MAX_COMMENT_LENGTH} className="form-control mt-1" value={meta.comment} onChange={handleOnChangeComment} placeholder={newTranslator.messages.escribirComentario} type="text" />
            <textarea className="form-control mt-1" value={meta.link} onChange={handleOnChangeUrl} placeholder={newTranslator.messages.pegarUrl} type="text" />
        </div>

    )

}
export default YoutubeLoader;