import React, { useEffect, useState } from 'react';
import { doNothing, Drawer } from '../components';
import { Text } from '@eo-locale/react';

const TagPreView = (props) => {
    const tagObject = props.tagObject.newTagObject.tag;
    const tagObjectResource = props.tagObject.newTagObject.resources;
    const [resourcesPreviews, setResourcesPreviews] = useState(doNothing())
    const validateResources = (value) => {
        if (value != null) {
            if (Array.isArray(value)) {
                return true;
            }
        }
        return false;
    }

    const renderBindings = () => {
        return tagObjectResource.map((resource) => (
            <Drawer resource={resource} />
        ))
    }

    useEffect(() => {
        if (validateResources(tagObjectResource)) {
            setResourcesPreviews(renderBindings());
        }
    }, [tagObjectResource])

    const Separator = () => (
        <hr style={{ border: '1px solid var(--divider-color)', width: '100%' }} />
    )

    return (
        //TODO agregar localizacion
        <div className="container d-flex flex-column">
            <h4 className="mb-4 font-tag"><Text id='vistaPrevia' /></h4>
            <p className="chip p1-medium button-chip ">#{tagObject.tag}</p>
            <h1 className="card-title text-center h1-medium mb-3 mt-3">{tagObject.title}</h1>
            <div className="">
                <p className="card-text h4-regular mb-4 word-break" style={{ color: "var(--secondary-text)" }}>{tagObject.description}</p>

            </div>
            <Separator />
            {resourcesPreviews}
        </div>
    )
}
export default TagPreView;




