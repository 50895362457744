import React from "react";
import { useTranslator } from "@eo-locale/react";

const MAX_TEXT_LENGTH = 400;
const ParagraphLoader = (props) => {
    const newTranslator = useTranslator();
    const { meta, onMetaChange, onValidChange } = props;
    const resource = {
        text: ''
    }

    /*  Cada vez que ocurre un cambio en el textArea viene a esta funcion y setea la metadata del resource en formResources.
    *   Como cargo la metadata pasada por props, si ya viene cargada me setea el value del textArea y puedo seguir editando.
    *   obligatoriamente le tengo que pasar un objeto al padre ya que nadie sabe que contiene adentro la metadata.
    *   Ej: no le puedo pasar el texto solo, ya que en FormResources se desconoce el contenido de la metadata.
    * */
    const handleOnChange = (e) => {
        resource.text = e.target.value;
        onMetaChange(resource);
        onValidChange(validate(e.target.value))
    }

    // Valido el contenido de la metadata.
    const validate = (value) => {
        // Esta es una validacion de ejemplo, meramente para pruebas.
        const isEmpty = value === "";
        return !isEmpty;
    }
    return (
        <div>
            <textarea maxLength={MAX_TEXT_LENGTH} className="form-control mt-1 box-shadow-primary" value={meta.text} onChange={handleOnChange} placeholder={newTranslator.messages.ingresarTexto} type="text" />
        </div>
    )
}

export default ParagraphLoader;