import React from 'react';

const Error = (props) => {

    const errorMsg = props.errorMsg ? props.errorMsg : "Error from component";

    return (
        <div>
            <p>{errorMsg}</p>
        </div>
    )

}
export default Error;
