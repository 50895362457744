import { TagEditor } from "../components";
import { useKeycloak } from "@react-keycloak/web";
import { Redirect } from "react-router";

const CreateTagView = () => {
    const { keycloak } = useKeycloak()

    const renderTagEditor = () => (
        <TagEditor keycloak={keycloak} bindings={false} />
    )

    return keycloak.authenticated ? renderTagEditor() : <Redirect to="" />;
}

export default CreateTagView;