import React, { useEffect, useState } from 'react';
import Loader from './Loader';
import sortDown from "./sort-down-solid.svg";
import { doNothing } from "./DoNothing";
import { Text, useTranslator } from "@eo-locale/react";


const typeList = [
    'youtubeVideo',
    'button',
    'markdown'
]

const ResourceEditor = (props) => {
    const newTranslator = useTranslator();
    const [resource, setResource] = useState(props.resource);
    const [renderDownButton, setRenderDownButton] = useState(props.renderDownButton)

    const id = props.id;
    const onResourceChange = props.onChange;
    const onElementDown = props.onElementDown;

    useEffect(() => {
        setRenderDownButton(props.renderDownButton)
    }, [props.renderDownButton])

    const updateResource = (res) => {
        let newResource = {
            ...resource,
            ...res
        }
        console.log(newResource)
        setResource(newResource);
        onResourceChange(newResource, id);
    }

    const processResource = (valid, metadata) => {
        let oldMetadata = resource.metadata;
        let newMetadata = {
            ...oldMetadata,
            ...metadata
        }
        updateResource({
            isValid: valid,
            metadata: newMetadata

        })
    }

    const setType = (e) => {
        updateResource({
            type: e.target.value
        })
    }

    const renderTypeSelect = () => {
        return (
            <select defaultValue="default" onChange={setType} className="form-select mt-1">
                <option value="default" disabled>{newTranslator.messages.tipo}</option>
                {
                    typeList.map((tipo, index) => (
                        <option value={tipo} key={index}>{newTranslator.messages[tipo]}</option>
                    ))
                }
            </select>
        )
    }

    const renderDeleteButton = () => {
        return (<button className="btn btn-block btn-danger" onClick={(e) => props.onDelete(id, e)} ><Text id='borrarRecurso' /></button>)
    }

    const renderButtonsChangePosition = () => {
        if (renderDownButton) {
            return (<button className="btn d-inline" onClick={(e) => onElementDown(e, id)}><img src={sortDown} alt="sortdown" width="60" height="60" /></button>)
        } else {
            return (doNothing())
        }
    }

    const renderEditable = () => {
        return <Loader onChange={processResource} type={resource.type} metadata={resource.metadata} />
    }

    return (
        <div className="d-flex">
            <div className="d-inline w-100">
                {resource.type === "" ? renderTypeSelect() : renderEditable()}
                <div className="mt-lg-2 mt-1" >
                    {renderDeleteButton()}
                </div>
            </div>
            {renderButtonsChangePosition()}
        </div>
    )
}
export default ResourceEditor;
