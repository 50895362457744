import React, { useState, useEffect } from 'react';
import validator from 'validator';
import { useTranslator } from "@eo-locale/react";


const ImageLoader = (props) => {
    const newTranslator = useTranslator();
    const { meta, onMetaChange, onValidChange } = props;
    const [resource, setResource] = useState({ comment: '', url: '' });

    useEffect(() => {
        const res = {
            ...resource,
            ...meta
        }
        setResource(res);
    }, [])

    const handleOnChangeComment = (e) => {
        let value = e.target.value;
        let res = {
            ...resource,
            comment: value
        }
        updateResource(res);
    }

    const handleOnChangeUrl = (e) => {
        let value = e.target.value;
        let res = {
            ...resource,
            url: value
        }
        updateResource(res);
    }

    const validateComment = (comment) => {
        return comment.length <= 300;
    }

    const validateUrl = async (url) => {
        if (validator.isURL(url)) {
            return await imageExists(url);
        } else {
            return false;
        }
    }

    const imageExists = (url) => {
        return new Promise(resolve => {
            var img = new Image();
            img.onload = () => { resolve(true) };
            img.onerror = () => { resolve(false) };
            img.src = url;
        })
    }

    const setValid = (value) => {
        // TODO Se deben optimizar los callback al padre. Solo se lo deberia llamar cuando
        //  el estado a setear es distinto al estado anterior.
        onValidChange(value)
    }

    const validatorResult = async (resource) => {
        let valid = (validateComment(resource.comment)) && (await validateUrl(resource.url));
        setValid(valid);
    }

    const updateResource = async (resource) => {
        validatorResult(resource);
        setResource(resource);
        onMetaChange(resource);
    }

    return (
        <div className="container">
            <textarea className="form-control mt-1" value={meta.comment} onChange={handleOnChangeComment} placeholder={newTranslator.messages.escribirComentario} type="text" />
            <textarea className="form-control mt-1" value={meta.url} onChange={handleOnChangeUrl} placeholder={newTranslator.messages.pegarUrl} type="text" />
        </div>
    )
}

export default ImageLoader;