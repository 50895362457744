import React from 'react';
import { TranslationsProvider } from '@eo-locale/react'
import {
  HomeView,
  ListView,
  UserView,
  CreateTagView,
  EditTagView,
  TagView,
} from './views';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import { AlertComponent, Nav, Loading } from "./components";
import ScrollToTop from "react-scroll-to-top";
import keycloak from "./keycloak";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { LocalizationStrings } from './localizationProvider'
import { useTranslator } from "@eo-locale/react";

function App() {
  const newTranslator = useTranslator();
  
  const userLanguage = () => {
    const aux = window.navigator.language;
    if (aux && typeof aux === 'string') {
      return aux.substr(0, 2)
    }
  }

  const language = localStorage.getItem('language') || userLanguage() || 'en';

  const handleEvents = (event) => {
    switch (event) {
      case 'onAuthSuccess':
        if (!(localStorage.getItem("alreadyNotified") === "true")) {
          localStorage.setItem("alreadyNotified", true);
          localStorage.setItem('isAuthenticated', true);
          AlertComponent({ title: newTranslator.messages.inicioSesion, timer: 2000 });
        }
        break;
      case 'onReady':
        let alreadyNotified = localStorage.getItem("alreadyNotified") === "true";
        let isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
        if (!isAuthenticated && alreadyNotified) {
          localStorage.setItem("alreadyNotified", false)
        }
        localStorage.setItem('isAuthenticated', false);
        break;
    }
  }

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onEvent={handleEvents}
      LoadingComponent={<Loading />}>
      <Router>
        <ScrollToTop smooth />
        <TranslationsProvider locales={LocalizationStrings} language={language} >
          <Nav />
          <Switch>
            <Route exact path='/' component={HomeView} />
            <Route exact path='/find/' component={HomeView} />
            <Route exact path='/find/:query' component={ListView} />
            <Route exact path='/users/:user_name' component={UserView} />
            <Route exact path='/create' component={CreateTagView} />
            <Route exact path='/edit/:tag' component={EditTagView} />
            <Route exact path='/:tag' component={TagView} />
            <Route exact path='/aboutus' />
          </Switch>
        </TranslationsProvider>
      </Router>
    </ReactKeycloakProvider>
  );
}

export default App;