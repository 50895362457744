import React from 'react';

const YoutubeDrawer = (props) => {
    const url = "https://www.youtube.com/embed/" + props.meta.url;
    return (
        <div className="drawable-component">
            <h3 className="h3-medium">{props.meta.title}</h3>
            <p className="p1-regular">{props.meta.comment}</p>
            <iframe title={props.meta.title} className="yt-video" src={url} frameBorder="0" />
        </div>
    )
}
export default YoutubeDrawer;