import React, { useState, useEffect } from 'react';
import { BasicUserInfo, DoNothing, ModalComponent, SecondaryButton, TagNameList, UserEditor } from '../components';
import { useKeycloak } from "@react-keycloak/web";
import { useTranslator, Text } from "@eo-locale/react";

const UserView = ({ match }) => {
    const newTranslator = useTranslator();
    const user_name = match.params.user_name;
    const [userObject, setUserObject] = useState({});
    const { keycloak, initialized } = useKeycloak()
    const [login, setLogin] = useState({});

    useEffect(() => {
        let headers = {}
        if (keycloak != null && keycloak.token != null) {
            headers = {
                ...headers,
                'Authorization': "Bearer " + keycloak.token,
            }
        }
        const URL_API = process.env.REACT_APP_URL_API;
        const url = `${URL_API}/api/users/${user_name}`;
        fetch(url, {
            headers: headers
        })
            .then((res) => res.json())
            .then((res) => {
                setUserObject(res);
            })
            .catch(() => {
                //FIXME 
            });
    }, [user_name]);

    const handleOnSave = (res) => {

        const URL_API = process.env.REACT_APP_URL_API
        const url = `${URL_API}/api/users/${user_name}/update`;
        fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Origin': 'https://tage.ar',
                'Authorization': "Bearer " + keycloak.token,
            },
            method: 'PATCH',
            body: JSON.stringify(res)
        }).then((res) => res.json())
            .then((res) => {
                setUserObject(res)
            })
            .catch(() => {
                console.log("error")
            })
    }
    useEffect(() => {
        if ((keycloak != null) && initialized) {
            keycloak.loadUserProfile()
                .then(userInfo => {
                    setLogin(userInfo);
                })
        }
    }, [keycloak, initialized])

    const validateUserName = () => {
        if (user_name === login.username) {
            return true;
        }
    }

    return (
        <div className="tag-view container mt-5 mb-5">
            <div className="card card-tag mb-5">
                <div>
                    <BasicUserInfo userObj={userObject} />
                    <ModalComponent title={newTranslator.messages.titleUserView} id="userEditor">
                        <UserEditor props={userObject} onSave={handleOnSave} />
                    </ModalComponent>
                    {validateUserName()
                        ?
                        <div className="mt-1 d-flex flex-row-reverse">
                            <SecondaryButton text={newTranslator.messages.editarPerfil} dataBsToggle="modal" dataBsTarget="#userEditor"/>
                        </div>
                        :
                        DoNothing()
                    }
                    <hr />
                    <h3 className="mt-5 mb-5" style={{ paddingLeft: "12px" }}><Text id="tagsCreadosPor" /> {userObject.display_name}</h3>
                    {validateUserName()
                        ?
                        <TagNameList id={user_name} showEdit={true} />
                        :
                        <TagNameList id={user_name} showEdit={false} />
                    }

                </div>
            </div>
        </div >
    )
}
export default UserView