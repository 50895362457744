import React from 'react';
import verifyCheck from './correct.svg';
import {DoNothing} from "./index";

const BasicUserInfo = (props) => {
    console.log(props)
    const user = props.userObj;

    return (
        <div>
            <div>
            <div className="user-view">
                    <img className="image" alt={user.profile_picture} src={user.profile_picture}/>
                <div className="name-user d-flex">
                    <p className="me-2">{user.display_name}</p>
                    { user.verified ?
                        <img width="45" height="45" src={verifyCheck} alt=""/>
                        :
                        <DoNothing/>
                    }
                </div>
                    <p className="description-user word-break">{user.description}</p>
                </div>
            </div>
           
        </div>
    )
}

export default BasicUserInfo;