import React, { useState, useEffect } from 'react';
import Editor from 'rich-markdown-editor';

const MarkdownLoader = (props) => {
    const { meta, onChange } = props;
    const [resource, setResource] = useState({ data: '' })

    useEffect(() => {
        const res = {
            ...resource,
            ...meta
        }
        setResource(res);
    }, [])

    const handleOnChangeData = (e) => {
        let value = e();
        let res = {
            ...resource,
            data: value
        }
        updateResource(res);
    }

    const validData = (data) => {
        return data.length > 2;
    }
    const updateResource = (resource) => {
        const valid = validData(resource.data)
        setResource(resource);
        onChange(valid, resource);
    }

    return (
        <div style={{ border: "1px solid rgba(0,0,0,0.5)", backgroundColor: "inherit" }}>
            <Editor
                defaultValue={meta.data}
                placeholder=""
                onChange={handleOnChangeData}
            />
        </div>
    );
}
export default MarkdownLoader