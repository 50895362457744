import React from "react";
import {
    ParagraphLoader,
    YoutubeLoader,
    ImageLoader,
    MarkdownLoader,
    ButtonLoader,
    NotFound,

} from "../drawers/loaders";

export default class Loader extends React.Component {

    constructor(props) {
        super(props);
        this.state = { type: props.type, metadata: props.metadata, handleChange: props.onChange }
    }

    render() {
        switch (this.state.type) {
            case "image":
                return (<ImageLoader meta={this.state.metadata} onChange={this.state.handleChange} />);
            case "paragraph":
                return (<ParagraphLoader meta={this.state.metadata} onChange={this.state.handleChange} />);
            case "youtubeVideo":
                return (<YoutubeLoader meta={this.state.metadata} onChange={this.state.handleChange} />);
            case "markdown":
                return (<MarkdownLoader meta={this.state.metadata} onChange={this.state.handleChange} />);
            case "button":
                return (<ButtonLoader meta={this.state.metadata} onChange={this.state.handleChange} />);
            default:
                return (<NotFound meta={this.state.metadata} type={this.state.type} />)
        }
    }
}
