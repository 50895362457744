import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Resource, doNothing, ShareMenu, ModalComponent, Loading, InformationTagComponent, SecondaryButton } from "../components";
import { RelatedTags } from "../components";
import { TagError } from "./errorViews";
import { Text, useTranslator } from "@eo-locale/react";

const TagView = ({ match }) => {
    const tag = match.params.tag;
    const newTranslator = useTranslator();
    const [isLoading, setIsLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [tagObject, setTagObject] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const URL_API = process.env.REACT_APP_URL_API;
                const url = `${URL_API}/api/${tag}`;
                const result = await fetch(url);
                if (!result.ok) {
                    const message = `An error has occured: ${result.status}`;
                    console.log(message);
                }
                const res = await result.json();
                if (res != null) {
                    setLoaded(true);
                    setTagObject(res);
                    console.log(res)
                }
            } catch (e) {
                console.log(e);
            }
            setIsLoading(false);
        }
        fetchData();
    }, [tag]);

    const renderBindings = () => {
        return tagObject.bindings.map((resource, index) => (
            <Resource key={index} id={resource} />
        ))
    }

    return (
        // FIXME Call to action a que hace refencia?
        isLoading ?
            <Loading />
            :
            <div className="tag-view container mt-5">
                {loaded ? (
                    <div className="card card-tag">
                        <InformationTagComponent
                            nroviews={tag.views ? tag.views : tagObject.views}
                            nroreloj={tag.expiration_date ? tag.expiration_date : tagObject.expiration_date}
                        />
                        <p className="chip p1-medium">#{tagObject.tag}</p>
                        <h1 className="card-title text-center h1-medium">{tagObject.title}</h1>
                        <div className="tag-info card-body">
                            <p className="card-text h4-regular mb-4" style={{ color: "var(--secondary-text)" }}>{tagObject.description}</p>
                            {tagObject.bindings != null ? renderBindings() : doNothing()}
                            <div className="d-grid gap-2">
                                <SecondaryButton extraClass='p-3 button-share' dataBsToggle='modal' dataBsTarget='#exampleModal' text={newTranslator.messages.compartir} />
                                <Link to={`/users/${tagObject.owner_id}`}>
                                    <SecondaryButton
                                        svg={<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.8284 7.82843C11.5786 7.07828 12 6.06087 12 5C12 3.93913 11.5786 2.92172 10.8284 2.17157C10.0783 1.42143 9.06087 1 8 1C6.93913 1 5.92172 1.42143 5.17157 2.17157C4.42143 2.92172 4 3.93913 4 5C4 6.06087 4.42143 7.07828 5.17157 7.82843C5.92172 8.57857 6.93913 9 8 9C9.06087 9 10.0783 8.57857 10.8284 7.82843Z" stroke="#212225" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M3.05025 14.0503C4.36301 12.7375 6.14348 12 8 12C9.85652 12 11.637 12.7375 12.9497 14.0503C14.2625 15.363 15 17.1435 15 19H1C1 17.1435 1.7375 15.363 3.05025 14.0503Z" stroke="#212225" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>}
                                        text={newTranslator.messages.verPerfilUsuario}
                                        extraClass='profile-button' />
                                </Link>
                            </div>
                            <ModalComponent title={newTranslator.messages.compartir}>
                                <ShareMenu tag={tag.toLowerCase()} />
                            </ModalComponent>
                        </div>
                        <hr />
                        <h3 className="h3-medium"><Text id='relacionadosConTuBusqueda' /></h3>
                        <RelatedTags match={tag} />
                    </div>
                ) : (
                    <TagError><Text id='tagNoExistente' /></TagError>
                )
                }
            </div >
    );
};

export default TagView;
