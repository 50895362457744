import React from "react";
import SecondaryButton from "../components/SecondaryButton";

const ButtonDrawer = (props) => {
    const url = props.meta.url;
    const name = props.meta.name;
    return (
        <div className="d-grid gap-2 mt-1">
            <SecondaryButton extraClass='p-3' target="_blank" text={name} href={url} />
        </div >
    )
}

export default ButtonDrawer;