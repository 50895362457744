import React, {Component} from 'react'
import Error from './Error'

class ErrorBoundary extends Component {
 constructor(props) {
     super(props);
     this.state = {
         hasError: false,
         error: null,
         errorInfo: null
     }
 }

 static getDerivedStateFromError() {
     return { hasError: true }
 }

 componentDidCatch(error, info) {
     this.setState({ error: error, errorInfo:info})
 }

 render () {
  if(this.state.hasError) {
    return <Error/>;
  }
  return (  
        this.props.children
  )
 }
}

export default ErrorBoundary;