import React, { useState } from 'react';
import DurationSelect from './DurationSelect';
import { TextInput } from "./index";
import TagNameEditor from './TagNameEditor';
import { doNothing } from '.';
import { Text, useTranslator } from "@eo-locale/react";


const MAX_TITLE_LENGTH = 30;
const MAX_DESCRIPTION_LENGTH = 140;

const TagForm = (props) => {
    const onChange = props.onChange;
    const editTagName = props.editTagName;
    const [tagObject, setTagObject] = useState({
        tag: props.defaultTag && props.defaultTag.tag ? props.defaultTag.tag : '',
        title: props.defaultTag && props.defaultTag.title ? props.defaultTag.title : '',
        description: props.defaultTag && props.defaultTag.description ? props.defaultTag.description : '',
        hours: editTagName ? 6 : undefined,
    });
    const newTranslator = useTranslator();

    const updateTagObject = (valid, res) => {
        const newTagObject = {
            ...tagObject,
            ...res
        }
        setTagObject(newTagObject);
        onChange(valid, newTagObject);
    }

    const onTagNameChange = (valid, tagName) => {
        updateTagObject(valid, {
            tag: tagName
        })
    }

    const handleChangeTitle = (e) => {
        setTitle(e.target.value);
    }

    const handleChangeDescription = (e) => {
        setDescription(e.target.value);
    }

    const setTitle = (value) => {
        let newTitle = value;
        if (newTitle.length <= MAX_TITLE_LENGTH)
            updateTagObject(true, {
                title: newTitle
            })
    }

    const setDescription = (value) => {
        let newDescription = value;
        if (newDescription.length <= MAX_DESCRIPTION_LENGTH) {
            updateTagObject(true, {
                description: newDescription
            })
        }
    }

    const updateHours = (hours) => {
        updateTagObject(true, {
            hours: hours
        })
    }

    return (
        <div>
            <h4 className="mb-4 font-tag"><Text id='nombreDeTag' /></h4>
            <TagNameEditor
                defaultTag={tagObject.tag}
                editTagName={editTagName}
                onChange={onTagNameChange}
            />
            <h4 className="mb-3 mt-3 font-tag"><Text id='titulo' /></h4>
            <TextInput
                maxLength={MAX_TITLE_LENGTH}
                defaultValue={tagObject.title}
                placeholder={newTranslator.messages.tituloInput}
                onChange={handleChangeTitle}
            />
            <h4 className="mb-3 mt-3 font-tag"><Text id='descripcion' /></h4>
            <textarea
                name=""
                id=""
                maxLength={MAX_DESCRIPTION_LENGTH}
                defaultValue={tagObject.description}
                cols="30" rows="5"
                placeholder={newTranslator.messages.descripcionInput}
                onChange={handleChangeDescription}
                className="form-control mt-2 box-shadow-primary font-tag"
            />
            {editTagName
                ?
                <div>
                    <label htmlFor="select-hours" className="mt-2 font-tag mb-2 mt-3"><Text id='duracion' /></label>
                    <DurationSelect onDurationChange={updateHours} />
                </div>
                :
                doNothing()
            }
        </div >
    )

}
export default TagForm;
