import React from 'react';
import {
    Dummy,
    YoutubeDrawer,
    ParagraphDrawer,
    ImageDrawer,
    MarkdownDrawer,
    ButtonDrawer,
    NotFound,

} from "../drawers";

const Drawer = (props) => {
    const resource = props.resource;

    switch (resource.type) {
        case "dummy":
            return (<Dummy meta={resource.metadata} />);
        case "youtubeVideo":
            return (<YoutubeDrawer meta={resource.metadata} />);
        case "paragraph":
            return (<ParagraphDrawer meta={resource.metadata} />);
        case "image":
            return (<ImageDrawer meta={resource.metadata} />);
        case "markdown":
            return (<MarkdownDrawer meta={resource.metadata} />);
        case "button":
            return (<ButtonDrawer meta={resource.metadata} />);
        default:
            return (<NotFound meta={resource.metadata} id={resource.id} />);
    }
}
export default Drawer